






































































































import { defineComponent, onMounted, reactive, ref } from '@vue/composition-api'
import { AxiosInstance } from 'node_modules/axios'

export default defineComponent({
  setup(_, { root }) {
    const state = reactive({
      loading: false,
      error: null as null | string
    })

    const leasing = ref<{ [key: string]: any } | null>(null)

    const fetch = () => {
      const $axios = root.$store.getters['api/getInstance'] as AxiosInstance

      $axios
        .get(`leasing/${root.$route.params.id}`)
        .then(({ data }) => {
          leasing.value = data.leasing
        })
        .catch(error => {
          if (error.response?.status) {
            switch (error.response.status) {
              case 404: state.error = 'Nie znaleziono leasingu'
                break
              case 403: state.error = 'Nie posiadasz uprawnień do wyświetlenia zasobu'
                break
              default: state.error = 'Wystąpił błąd'
            }
          }
        })
    }

    onMounted(fetch)

    return {
      state,
      leasing,
      fetch
    }
  },
})
